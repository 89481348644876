import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import BookAnAppointment from "../shared/sections/BookAnAppointment";

const MakeAnAppointment = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Make an Appointment" />}>
			<BookAnAppointment />
		</Layout>
	);
};

export default MakeAnAppointment;
