import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import ContactSection from "../shared/sections/ContactSection";
import PortfolioDetailSection from "../shared/sections/PortfolioDetailSection";

const PortfolioDetail = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Portfolio Details" />}>
			<PortfolioDetailSection />
			<ContactSection />
		</Layout>
	);
};

export default PortfolioDetail;
