import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import ContactSection from "../shared/sections/ContactSection";
import BlogSection from "../shared/sections/BlogSection";

const BlogsGrid = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Blogs Grid" />}>
			<BlogSection noHead />
			<ContactSection />
		</Layout>
	);
};

export default BlogsGrid;
