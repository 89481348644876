import React from "react";
import Heading from "../components/Heading";
import Accordian from "../components/Accordian";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const FAQSection = () => {
	return (
		<div className="py-[100px] w-full bg-shape-placeholder bg-top bg-no-repeat">
			<div className="main-container-big text-troo-light h-full flex flex-col items-center w-full gap-16">
				<Heading
					headline="Frequently asked questions"
					align="center"
					title={
						<>
							Questions in mind?
							<br /> We have answer your question.
						</>
					}
				/>
				<div className="w-40 h-[2px] bg-troo-tertiary" />
				<div className="w-full flex flex-col items-center gap-4">
					<Accordian
						srNo="1"
						title="Do you deliver every image you shoot?"
						desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
					/>
					<Accordian
						srNo="2"
						title="Does your studio provide videography services?"
						desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
					/>
					<Accordian
						srNo="3"
						title="Do you do destination weddings?"
						desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
					/>
					<Accordian
						srNo="4"
						title="Have you shot at my venue before?"
						desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
					/>
					<Accordian
						srNo="5"
						title="Which photographer will be shooting my wedding?"
						desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
					/>
					<Accordian
						srNo="6"
						title=" Do you shoot in JPEG, Small Raw, or Large Raw?"
						desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
					/>
				</div>
				<Link to={ROUTES.faq} className="self-center">
					<Button className="btn-theme-outlined">View All FAQs</Button>
				</Link>
			</div>
		</div>
	);
};

export default FAQSection;
