import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "./../../assets/images/logo/brand.svg";
import Button from "./Button";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { IoMailOpenOutline } from "react-icons/io5";
import { NAV_ROUTES } from "../constants/navRoutes";
import { ROUTES } from "../constants/routes";
import AccordianMenu from "./AccordianMenu";
import useWindowScroll from "../hooks/useWindowScroll";

const Header = () => {
	const location = useLocation();
	const scrolled = useWindowScroll(90);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);
	return (
		<div
			className={`drawer z-10 flex md:relative w-full flex-col items-start ${
				scrolled && "fixed top-0 bg-troo-primary"
			}`}
		>
			<input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content main-container w-full flex items-center justify-between xl:grid grid-cols-3 py-5 text-troo-light">
				<div className="hidden xl:flex items-center gap-4">
					<div className="flex items-center gap-1">
						<FiPhoneCall className="text-2xl" />
						<p className="p-2">+44 123 456 7890</p>
					</div>
					<span className="divider-dot" />
					<div className="flex items-center gap-1">
						<IoMailOpenOutline className="text-2xl" />
						<p className="p-2">contact@troothemes.com</p>
					</div>
				</div>
				<div className="flex justify-center">
					<Link to={ROUTES.home}>
						<img src={logo} alt="logo" />
					</Link>
				</div>
				<div className="hidden md:flex justify-end">
					<Link to={ROUTES.makeAnAppointment}>
						<Button className="btn-theme-outlined">Book For Click</Button>
					</Link>
				</div>
				<div className="flex md:hidden justify-end">
					<label htmlFor="my-drawer-3">
						<HiOutlineMenuAlt3 className="text-3xl text-troo-light" />
					</label>
				</div>
			</div>
			<div className="drawer-side z-50">
				<label htmlFor="my-drawer-3" className="drawer-overlay"></label>
				<div className="menu p-4 overflow-scroll no-scrollbar w-[80%] md:w-80 h-full bg-troo-secondary text-troo-light">
					{/* Sidebar content here */}
					<div className="flex flex-col items-start gap-10 lg:gap-6 2xl:gap-8">
						<div className="flex items-center">
							<Link href={ROUTES.home}>
								<img src={logo} alt="trooPhotography" />
							</Link>
						</div>
						<div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
							{NAV_ROUTES.map((route, i) => {
								if (!route?.isMultiple) {
									return (
										<Link
											key={i}
											to={route.path}
											className={`py-1 ${
												location.pathname === route.path && "activeMobileNav"
											}`}
										>
											{route.name}
										</Link>
									);
								} else {
									return (
										<AccordianMenu
											key={i}
											title={route.name}
											subMenu={route.subPages}
										/>
									);
								}
							})}
						</div>
						<div className="w-full text-center">
							<Link to={ROUTES.makeAnAppointment}>
								<Button className="btn-theme-outlined">Book For Click</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
