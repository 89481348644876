import React from "react";
import { HiOutlineArrowUpRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const GalaryCard = ({ img, category, title }) => {
  return (
    <div className="relative w-full overflow-hidden group cursor-pointer">
      <Link to={ROUTES.portfolioDetails}>
        <div className="flex transition-all skew-y-12 group-hover:skew-y-0 -translate-x-[100%] group-hover:translate-x-0 z-10 absolute top-0 left-0 flex-col items-center justify-center w-full h-full bg-troo-secondary bg-opacity-50 backdrop-blur">
          <HiOutlineArrowUpRight className="text-6xl text-troo-light text-opacity-50" />
          <div className="absolute bottom-6 left-8 flex gap-4 flex-col items-start text-troo-light self-start">
            <p>{category}</p>
            <h5>{title}</h5>
          </div>
        </div>
      </Link>
      <img
        src={img}
        alt="img"
        className="w-full h-full object-cover group-hover:scale-125 transition-all"
      />
    </div>
  );
};

export default GalaryCard;
