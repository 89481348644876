import React from "react";
import Accordian from "../components/Accordian";
import FAQArrow from "../../assets/images/shapes/faq-arrow.svg";

const FAQDetails = () => {
	return (
		<div className="py-[100px] w-full bg-shape-placeholder bg-top bg-no-repeat">
			<div className="main-container-big text-troo-light h-full flex flex-col items-center w-full gap-16">
				<div className="flex flex-col items-start gap-8">
					<h4>General Questions</h4>
					<div className="flex items-start gap-4">
						<img src={FAQArrow} alt="FAQ Arrow" className="hidden md:block" />
						<div className="w-full flex flex-col mt-0 md:mt-6 items-center gap-4">
							<Accordian
								srNo="1"
								title="Do you deliver every image you shoot?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
							<Accordian
								srNo="2"
								title="Does your studio provide videography services?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
							<Accordian
								srNo="3"
								title="Do you do destination weddings?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
							<Accordian
								srNo="4"
								title="Have you shot at my venue before?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
							<Accordian
								srNo="5"
								title="Which photographer will be shooting my wedding?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
							<Accordian
								srNo="6"
								title=" Do you shoot in JPEG, Small Raw, or Large Raw?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-col items-start gap-8">
					<h4>Style and Quality Questions</h4>
					<div className="flex items-start gap-4">
						<img src={FAQArrow} alt="FAQ Arrow" className="hidden md:block" />
						<div className="w-full flex flex-col mt-0 md:mt-6 items-center gap-4">
							<Accordian
								srNo="1"
								title="Do you deliver every image you shoot?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
							<Accordian
								srNo="2"
								title="Does your studio provide videography services?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
							<Accordian
								srNo="3"
								title="Do you do destination weddings?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
							<Accordian
								srNo="4"
								title="Have you shot at my venue before?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
							<Accordian
								srNo="5"
								title="Which photographer will be shooting my wedding?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
							<Accordian
								srNo="6"
								title=" Do you shoot in JPEG, Small Raw, or Large Raw?"
								desc="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable."
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FAQDetails;
