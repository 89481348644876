import React from "react";
import Aerial from "../../assets/images/services/Icon-1.svg";
import Videography from "../../assets/images/services/Icon-2.svg";
import Dress from "../../assets/images/services/Icon-4.svg";
import Camera from "../../assets/images/services/Icon.svg";
import Commercial from "../../assets/images/services/commercial.svg";
import Editorial from "../../assets/images/services/editorial.svg";
import Model from "../../assets/images/services/model.svg";
import Person from "../../assets/images/services/person.svg";

const ServiceGrid = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				<h2 className="text-center">
					I'll ensure you always get the
					<br /> best photography service
				</h2>
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-8">
					<div className="flex flex-col items-center gap-3 md:gap-6">
						<div className="w-20 md:w-auto">
							<img
								src={Camera}
								alt="Camera"
								className="object-contain w-full h-full"
							/>
						</div>
						<h6 className="whitespace-nowrap">Photography</h6>
					</div>
					<div className="flex flex-col items-center gap-3 md:gap-6">
						<div className="w-20 md:w-auto">
							<img
								src={Videography}
								alt="Videography"
								className="object-contain w-full h-full"
							/>
						</div>
						<h6 className="whitespace-nowrap">Videography</h6>
					</div>
					<div className="flex flex-col items-center gap-3 md:gap-6">
						<div className="w-20 md:w-auto">
							<img
								src={Aerial}
								alt="Aerial"
								className="object-contain w-full h-full"
							/>
						</div>
						<h6 className="whitespace-nowrap">Aerial Photo</h6>
					</div>
					<div className="flex flex-col items-center gap-3 md:gap-6">
						<div className="w-20 md:w-auto">
							<img
								src={Dress}
								alt="Dress"
								className="object-contain w-full h-full"
							/>
						</div>
						<h6 className="whitespace-nowrap">Fashion Photo</h6>
					</div>
					<div className="flex flex-col items-center gap-3 md:gap-6">
						<div className="w-20 md:w-auto">
							<img
								src={Commercial}
								alt="Commercial"
								className="object-contain w-full h-full"
							/>
						</div>
						<h6 className="whitespace-nowrap">Commercial</h6>
					</div>
					<div className="flex flex-col items-center gap-3 md:gap-6">
						<div className="w-20 md:w-auto">
							<img
								src={Person}
								alt="Person"
								className="object-contain w-full h-full"
							/>
						</div>
						<h6 className="whitespace-nowrap">Personal</h6>
					</div>
					<div className="flex flex-col items-center gap-3 md:gap-6">
						<div className="w-20 md:w-auto">
							<img
								src={Editorial}
								alt="Editorial"
								className="object-contain w-full h-full"
							/>
						</div>
						<h6 className="whitespace-nowrap">Editorial</h6>
					</div>
					<div className="flex flex-col items-center gap-3 md:gap-6">
						<div className="w-20 md:w-auto">
							<img
								src={Model}
								alt="Model"
								className="object-contain w-full h-full"
							/>
						</div>
						<h6 className="whitespace-nowrap">Model</h6>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceGrid;
