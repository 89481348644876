import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { ROUTES } from "./shared/constants/routes";
import About from "./pages/About";
import Services from "./pages/Services";
import ServiceDetails from "./pages/ServiceDetails";
import Portfolio from "./pages/Portfolio";
import PortfolioDetails from "./pages/PortfolioDetails";
import Testimonial from "./pages/Testimonial";
import Team from "./pages/Team";
import Pricing from "./pages/Pricing";
import BlogsGrid from "./pages/BlogsGrid";
import ErrorPage from "./pages/404";
import ComingSoon from "./pages/ComingSoon";
import FAQ from "./pages/FAQ";
import MakeAnAppointment from "./pages/MakeAnAppointment";
import ContactUS from "./pages/ContactUs";
import BlogsList from "./pages/BlogList";
import BlogDetails from "./pages/BlogDetails";
import TeamDetails from "./pages/TeamDetails";

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path={ROUTES.home} element={<Home />} />
					<Route path={ROUTES.aboutUs} element={<About />} />
					<Route path={ROUTES.services} element={<Services />} />
					<Route path={ROUTES.serviceDetails} element={<ServiceDetails />} />
					<Route path={ROUTES.portfolio} element={<Portfolio />} />
					<Route
						path={ROUTES.portfolioDetails}
						element={<PortfolioDetails />}
					/>
					<Route path={ROUTES.testimonial} element={<Testimonial />} />
					<Route path={ROUTES.team} element={<Team />} />
					<Route path={ROUTES.pricing} element={<Pricing />} />
					<Route path={ROUTES.blogsGrid} element={<BlogsGrid />} />
					<Route path={ROUTES.errorPage} element={<ErrorPage />} />
					<Route path={ROUTES.comingSoon} element={<ComingSoon />} />
					<Route path={ROUTES.faq} element={<FAQ />} />
					<Route path={ROUTES.blogsList} element={<BlogsList />} />
					<Route path={ROUTES.blogDetails} element={<BlogDetails />} />
					<Route path={ROUTES.contactPage} element={<ContactUS />} />
					<Route path={ROUTES.teamDetails} element={<TeamDetails />} />
					<Route
						path={ROUTES.portfolioDetails}
						element={<PortfolioDetails />}
					/>
					<Route
						path={ROUTES.makeAnAppointment}
						element={<MakeAnAppointment />}
					/>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
