import React from "react";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import Layout from "../shared/components/Layout";
import ContactSection from "../shared/sections/ContactSection";
import FAQDetails from "../shared/sections/FAQDetails";

const FAQ = () => {
	return (
		<Layout banner={<SubPageHeroSection title="FAQs" />}>
			<FAQDetails />
			<ContactSection />
		</Layout>
	);
};

export default FAQ;
