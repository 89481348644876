import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import ContactSection from "../shared/sections/ContactSection";
import BlogDetailSection from "../shared/sections/BlogDetailSection";

const BlogDetails = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Blogs Details" />}>
			<BlogDetailSection noHead />
			<ContactSection />
		</Layout>
	);
};

export default BlogDetails;
