import { ROUTES } from "./routes";
import Camera from "../../assets/images/sidebarIcons/photograph-camera.svg";
import Videography from "../../assets/images/sidebarIcons/news-camera.svg";
import ArialPhotos from "../../assets/images/sidebarIcons/icon-drone.svg";
import Fashion from "../../assets/images/sidebarIcons/icon-coat.svg";
import Wedding from "../../assets/images/sidebarIcons/wedding-ring.svg";
import Personal from "../../assets/images/sidebarIcons/person-image.svg";

export const NAV_ROUTES = [
	{
		path: ROUTES.home,
		name: "Home",
	},
	{
		path: ROUTES.aboutUs,
		name: "About Us",
	},
	{
		name: "Services",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.services,
				name: "Service",
			},
			{
				path: ROUTES.serviceDetails,
				name: "Service Detail",
			},
		],
	},
	{
		name: "Pages",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.team,
				name: "Team",
			},
			{
				path: ROUTES.teamDetails,
				name: "Team Detail",
			},
			{
				path: ROUTES.testimonial,
				name: "Testimonial",
			},
			{
				path: ROUTES.faq,
				name: "FAQ",
			},
			{
				path: ROUTES.pricing,
				name: "Pricing",
			},
			{
				path: ROUTES.makeAnAppointment,
				name: "Make an Appointment",
			},
			{
				path: ROUTES.errorPage,
				name: "404",
			},
			{
				path: ROUTES.comingSoon,
				name: "Coming Soon",
			},
		],
	},
	{
		name: "Portfolio",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.portfolio,
				name: "Portfolio",
			},
			{
				path: ROUTES.portfolioDetails,
				name: "Portfolio Detail",
			},
		],
	},
	{
		name: "Blogs",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.blogsGrid,
				name: "Blogs Grid",
			},
			{
				path: ROUTES.blogsList,
				name: "Blogs List",
			},
			{
				path: ROUTES.blogDetails,
				name: "Blog Details",
			},
		],
	},
	{
		path: ROUTES.contactPage,
		name: "Contact Me",
	},
];

export const SECONDARY_SIDEBAR = [
	{
		title: "Photography",
		img: Camera,
	},
	{
		title: "Videography",
		img: Videography,
	},
	{
		title: "Arial Photos",
		img: ArialPhotos,
	},
	{
		title: "Fashion Photos",
		img: Fashion,
	},
	{
		title: "Wedding Photos",
		img: Wedding,
	},
	{
		title: "Personal Photos",
		img: Personal,
	},
];
