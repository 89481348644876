import React from "react";
import Logo1 from "../../assets/images/brands/logo_01.png";
import Logo2 from "../../assets/images/brands/logo_02.png";
import Logo3 from "../../assets/images/brands/logo_03.png";
import Logo4 from "../../assets/images/brands/logo_04.png";
import Logo5 from "../../assets/images/brands/logo_05.png";
import Logo6 from "../../assets/images/brands/logo_06.png";

const FooterBrands = () => {
	return (
		<div className="main-container-big bg-transparent">
			<div className="bg-troo-primary w-full p-5 sm:py-10 sm:px-8 overflow-hidden shadow-lg shadow-troo-tertiary/20 box-border grid grid-cols-3 sm:grid-cols-6 gap-5">
				<div className="w-full h-full flex items-center justify-center">
					<img
						src={Logo1}
						alt="logo"
						className="w-full h-full object-contain"
					/>
				</div>
				<div className="w-full h-full flex items-center justify-center">
					<img
						src={Logo2}
						alt="logo"
						className="w-full h-full object-contain"
					/>
				</div>
				<div className="w-full h-full flex items-center justify-center">
					<img
						src={Logo3}
						alt="logo"
						className="w-full h-full object-contain"
					/>
				</div>
				<div className="w-full h-full flex items-center justify-center">
					<img
						src={Logo4}
						alt="logo"
						className="w-full h-full object-contain"
					/>
				</div>
				<div className="w-full h-full flex items-center justify-center">
					<img
						src={Logo5}
						alt="logo"
						className="w-full h-full object-contain"
					/>
				</div>
				<div className="w-full h-full flex items-center justify-center">
					<img
						src={Logo6}
						alt="logo"
						className="w-full h-full object-contain"
					/>
				</div>
			</div>
		</div>
	);
};

export default FooterBrands;
