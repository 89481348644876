import React from "react";
import GalaryImg1 from "../../assets/images/galary/image-1.png";
import GalaryImg2 from "../../assets/images/galary/image.png";
import Img2 from "../../assets/images/blogs/image-2.png";
import Img3 from "../../assets/images/blogs/image-3.png";
import BlogCategory from "../components/BlogCategory";
import BlogTags from "../components/BlogTags";
import BlogListWidget from "../components/BlogListWidget";
import { FiCheckSquare } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import Comments from "../components/Comments";

const BlogDetailSection = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light grid h-full grid-cols-1 lg:grid-cols-3 w-full gap-16">
				<div className="flex flex-col gap-8 w-full lg:col-span-2">
					<h2>
						This Weird Filter and Vintage Lens Create Amazing Images Together
					</h2>
					<div className="relative w-full h-full lg:h-[530px] overflow-hidden">
						<img src={Img3} alt="blog" className="w-full h-full object-cover" />
						<div className="absolute bottom-3 right-3 bg-troo-secondary p-5 flex flex-col gap-2">
							<p>
								<span className="font-bold">Post By:</span> Michael Waugh
							</p>
							<p>
								<span className="font-bold">Category:</span> Photography
							</p>
							<p>
								<span className="font-bold">Date:</span> 25 December, 2022
							</p>
						</div>
					</div>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable. If you are going to use a passage of Lorem Ipsum, you
						need to be sure there isn't anything embarrassing hidden in the
						middle of text. All the Lorem Ipsum generators on the Internet tend
						to repeat predefined chunks as necessary, making this the first true
						generator on the Internet. It uses a dictionary of over 200 Latin
						words.
					</p>
					<p>
						Variations of passages of Lorem Ipsum available, but the majority
						have suffered alteration in some form, by injected humour, or
						randomized words which don't look even slightly believable. If you
						are going to use a passage of Lorem Ipsum, you need to be sure
						there.
					</p>
					<div className="bg-troo-secondary p-8 border-l-4 border-troo-tertiary text-troo-tertiary">
						<p className="font-bold leading-7">
							There are many variations of passages of Lorem Ipsum nd it
							available, but the majority have suffered alteration in some and
							it form, by injected humour, or randomized words which don't look
							even slightly believable. If you are going to use a passage of
							Lorem Ipsum, you need to be sure there.
						</p>
						<h6 className="text-right">-- John Doe</h6>
					</div>
					<p>
						Many variations of passages of Lorem Ipsum available, but the
						majority have suffered alteration in some form, by injected humour,
						or randomised words which don't look even slightly believable. If
						you are going to use a passage of Lorem Ipsum, you need to be sure
						there isn't anything embarrassing hidden in the middle.s
					</p>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
						<div className="w-full h-full overflow-hidden">
							<img
								src={Img2}
								alt="img"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="flex flex-col items-start gap-8">
							<h5>Essential Service</h5>
							<p>
								There are many variations of the in passages by injected humour
							</p>
							<div className="flex flex-col items-start gap-4">
								<div className="flex items-start gap-3">
									<div>
										<FiCheckSquare className="text-xl" />
									</div>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									</p>
								</div>
								<div className="flex items-start gap-3">
									<div>
										<FiCheckSquare className="text-xl" />
									</div>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing
										elit.Lorem ipsum dolor siconse ctetur adipist amet.
									</p>
								</div>
								<div className="flex items-start gap-3">
									<div>
										<FiCheckSquare className="text-xl" />
									</div>
									<p>Lorem ipsum dolor sit amet consectetur adipis.</p>
								</div>
								<div className="flex items-start gap-3">
									<div>
										<FiCheckSquare className="text-xl" />
									</div>
									<p>
										Lorem There are many variations of passages of Lorem dolor
										sit.
									</p>
								</div>
							</div>
						</div>
					</div>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomized words which don't look even slightly
						believable. If you are and going to use a passage of Lorem Ipsum
						randomized words which don't look even slightly believable.
					</p>
					<div className="grid grid-cols-2 gap-4">
						<div className="w-full h-full overflow-hidden">
							<img
								src={GalaryImg1}
								alt="img"
								className="w-full h-full object-cover"
							/>
						</div>
						<div className="w-full h-full overflow-hidden">
							<img
								src={GalaryImg2}
								alt="img"
								className="w-full h-full object-cover"
							/>
						</div>
					</div>
					<div className="flex flex-col gap-3 md:flex-row items-start md:items-center justify-between py-3 border-y border-troo-tertiary">
						<p>
							<span className="font-bold">Tags: </span> Photography,
							Videography, Advertising
						</p>
						<div className="flex flex-wrap items-center gap-2">
							<parseInt>Share on</parseInt>
							<div className="flex items-center gap-2">
								<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
									<FaFacebookF />
								</div>
								<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
									<AiOutlineTwitter />
								</div>
								<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
									<FaLinkedinIn />
								</div>
								<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
									<BsInstagram />
								</div>
							</div>
						</div>
					</div>
					<Comments />
				</div>
				<div className="w-full flex flex-col gap-6">
					<BlogCategory />
					<BlogTags />
					<BlogListWidget />
				</div>
			</div>
		</div>
	);
};

export default BlogDetailSection;
