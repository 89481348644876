import React from "react";
import { BsEnvelopeOpen, BsTelephonePlus } from "react-icons/bs";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const ContactBanner = () => {
	return (
		<div className="py-[100px] w-full bg-contact-banner bg-no-repeat bg-cover bg-center">
			<div className="main-container-big text-center text-troo-light h-full flex flex-col items-center justify-center w-full gap-8">
				<h2>
					Need a photographer?
					<br /> someone with experience to
					<br /> collaborate with?
				</h2>
				<div className="bg-troo-tertiary text-troo-light bg-opacity-25 w-full md:w-auto px-2 md:px-10 py-6 rounded-lg flex md:flex-row flex-col items-center gap-10">
					<div className="flex items-center gap-4">
						<BsTelephonePlus className="text-xl md:text-3xl" />
						<div className="flex flex-col items-start">
							<p>Call me on</p>
							<h6>+44 123 456 7890</h6>
						</div>
					</div>
					<div className="hidden md:block w-[2px] h-16 bg-troo-tertiary" />
					<div className="flex items-center gap-4">
						<BsEnvelopeOpen className="text-xl md:text-3xl" />
						<div className="flex flex-col items-start">
							<p>Email me on</p>
							<h6>example@email.com</h6>
						</div>
					</div>
				</div>
				<Link to={ROUTES.contactPage}>
					<Button className="btn-theme-outlined">Contact Me</Button>
				</Link>
			</div>
		</div>
	);
};

export default ContactBanner;
