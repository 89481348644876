import React from "react";
import Img1 from "../../assets/images/blogs/image-1.png";
import Img2 from "../../assets/images/blogs/image-2.png";
import Img3 from "../../assets/images/blogs/image-3.png";
import BlogListCardSmall from "./BlogListCardSmall";

const BlogListWidget = () => {
	return (
		<div className="w-full flex flex-col items-start">
			<div className="bg-troo-secondary w-full text-troo-light px-6 py-5">
				<h6>Recent News</h6>
			</div>
			<div className="grid grid-cols-1 border border-troo-light border-opacity-25 gap-2 p-3">
				<BlogListCardSmall
					img={Img1}
					heading="This Weird Filter and Vintage Lens Create Amazing Images Together"
				/>
				<BlogListCardSmall
					img={Img2}
					heading="How to Make a Lens from a Disposable Camera (90s film look)"
				/>
				<BlogListCardSmall
					img={Img3}
					heading="Creating The Darkest Photo Backdrop in The World (99,9%)"
				/>
			</div>
		</div>
	);
};

export default BlogListWidget;
