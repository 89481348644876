import React from "react";
import ErrImg from "../assets/images/placeholder/404.svg";
import Button from "../shared/components/Button";

const ErrorPage = () => {
	return (
		<div className="w-full h-screen bg-error-banner bg-no-repeat bg-cover bg-center">
			<div className="w-full h-full bg-troo-primary text-troo-light bg-opacity-70 flex flex-col items-start justify-center p-10 md:p-20 lg:p-28 gap-5">
				<p className="font-bold text-xl">Error</p>
				<img src={ErrImg} alt="404" />
				<h5>Sorry, It is not you. It is us</h5>
				<p className="">
					It looks like nothing was found at this location. You can either go
					back to the last page or go to Homepage
				</p>
				<Button className="btn-theme-outlined">Back to Homepage</Button>
			</div>
		</div>
	);
};

export default ErrorPage;
