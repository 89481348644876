import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import ClientReviews from "../shared/sections/ClientReviews";
import ContactSection from "../shared/sections/ContactSection";

const Testimonial = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Testimonial" />}>
			<ClientReviews noHead />
			<ContactSection />
		</Layout>
	);
};

export default Testimonial;
