import React from "react";
import Heading from "../components/Heading";
import ProcessCard from "../components/ProcessCard";
import RightArrow from "../../assets/images/shapes/arrow.svg";

const WorkProcess = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				<Heading
					align="center"
					headline="Working process"
					title={
						<>
							I have some easy steps to
							<br /> complete projects.
						</>
					}
				/>
				<div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 lg:gap-0 items-center justify-center">
					<ProcessCard
						srNo="01"
						headline="Send an Email"
						desc="Lorem ipsum Come to Us to do the best work, with the best team."
					/>
					<img src={RightArrow} alt="arrow" className="hidden md:block" />
					<ProcessCard
						srNo="02"
						headline="Clicks the pictures"
						desc="Lorem ipsum Come to Us to do the best work, with the best team."
					/>
					<img src={RightArrow} alt="arrow" className="hidden md:block" />
					<ProcessCard
						srNo="03"
						headline="Get your album"
						desc="Lorem ipsum Come to Us to do the best work, with the best team."
					/>
				</div>
			</div>
		</div>
	);
};

export default WorkProcess;
