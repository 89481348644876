import React from "react";

const Input = ({ placeholder, type, icon, ...rest }) => {
	return (
		<div className="flex items-center w-full px-1 py-3 justify-between border-b border-troo-light border-opacity-50">
			<input
				type={type}
				placeholder={placeholder}
				{...rest}
				className="bg-transparent outline-none w-full"
			/>
			<div className="text-troo-light text-xl">{icon}</div>
		</div>
	);
};

export default Input;
