import React from "react";
import imgGirl from "../../assets/images/placeholder/girl.png";
import { FaFacebookF } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { IoMailOpenOutline } from "react-icons/io5";

const TeamDetailSection = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light flex flex-col gap-12 items-start">
				<div className="w-full flex flex-col lg:flex-row gap-8 items-start">
					<div className="relative w-full lg:w-[40%] h-full overflow-hidden">
						<img
							src={imgGirl}
							alt="girl"
							className="w-full h-full object-cover"
						/>
						<div className="absolute bottom-0 left-0 w-full bg-troo-secondary py-4 flex flex-wrap items-center justify-center gap-2">
							<h6>Connect</h6>
							<div className="flex items-center gap-2">
								<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
									<FaFacebookF />
								</div>
								<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
									<AiOutlineTwitter />
								</div>
								<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
									<FaLinkedinIn />
								</div>
								<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
									<BsInstagram />
								</div>
							</div>
						</div>
					</div>
					<div className="w-full lg:w-[60%] flex flex-col items-start gap-6">
						<div className="w-20 h-[2px] bg-troo-light" />
						<h2>
							Cristiana Joe New York based photographer. she have a 15+ year
							experience in photography & videography.
						</h2>
						<p>
							Contrary to popular belief, Lorem Ipsum is not simply random text.
							It has roots in a piece of classical Latin literature from 45 BC,
							making Contrary to popular belief.
						</p>
						<div className="py-4 px-6 w-full lg:w-auto flex flex-col items-start gap-3 bg-troo-secondary">
							<h6>Contact Info</h6>
							<div className="w-12 h-[1px] bg-troo-tertiary" />
							<div className="flex flex-col sm:flex-row sm:items-center gap-6">
								<div className="flex items-center gap-3">
									<FiPhoneCall className="text-xl" />
									<p>+44 123 456 7890</p>
								</div>
								<div className="flex items-center gap-3">
									<IoMailOpenOutline className="text-xl" />
									<p>example@email.com</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full flex flex-col lg:flex-row gap-8 items-start py-12">
					<div className="w-full lg:w-[40%] flex flex-col gap-5">
						<p>What She Do</p>
						<h2>Passionate and dedicated to work</h2>
					</div>
					<div className="w-full lg:w-[60%] grid grid-cols-2 gap-6 md:gap-12">
						<div className="flex flex-col items-start gap-4">
							<h5>Photography</h5>
							<div className="w-28 h-[2px] bg-troo-light" />
							<span className="text-xs sm:text-sm">
								Lorem ipsum dolort amet, consectetur adipisicing elit, sed do
								eiusmod tempor incididunt ut labore.
							</span>
						</div>
						<div className="flex flex-col items-start gap-4">
							<h5>Photography</h5>
							<div className="w-28 h-[2px] bg-troo-light" />
							<span className="text-xs sm:text-sm">
								Lorem ipsum dolort amet, consectetur adipisicing elit, sed do
								eiusmod tempor incididunt ut labore.
							</span>
						</div>
						<div className="flex flex-col items-start gap-4">
							<h5>Photo Editing</h5>
							<div className="w-28 h-[2px] bg-troo-light" />
							<span className="text-xs sm:text-sm">
								Lorem ipsum dolort amet, consectetur adipisicing elit, sed do
								eiusmod tempor incididunt ut labore.
							</span>
						</div>
						<div className="flex flex-col items-start gap-4">
							<h5>Video Editing</h5>
							<div className="w-28 h-[2px] bg-troo-light" />
							<span className="text-xs sm:text-sm">
								Lorem ipsum dolort amet, consectetur adipisicing elit, sed do
								eiusmod tempor incididunt ut labore.
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TeamDetailSection;
