import React, { useEffect, useRef } from "react";
import Header from "./Header";
import HeaderMenu from "./HeaderMenu";
import Footer from "./Footer";
import FooterBrands from "./FooterBrands";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../constants/routes";
const Layout = ({ banner, children, errorPage }) => {
	const parallaxRef = useRef(null);
	const location = useLocation();

	useEffect(() => {
		const handleScroll = () => {
			const scrolled = window.scrollY;
			if (parallaxRef.current) {
				parallaxRef.current.style.backgroundPositionY = `${scrolled * 0.4}px`;
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	return (
		<>
			<div
				ref={parallaxRef}
				className={`${
					location.pathname === ROUTES.home
						? "bg-home-hero-banner"
						: "bg-camera-banner"
				} bg-no-repeat bg-cover bg-center`}
				id="parallax"
			>
				<Header />
				<HeaderMenu />
				<div className="">{banner}</div>
			</div>
			<main>{children}</main>
			{!errorPage && (
				<>
					<div className="bg-troo-secondary w-full h-20 sm:h-10 md:h-24 lg:h-36" />
					<div className="relative flex flex-col items-center">
						<Footer />
						<div className="absolute -top-[18%] sm:-top-[12%] md:-top-[32%] lg:-top-[45%] xl:-top-[50%] w-full">
							<FooterBrands />
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Layout;
