import React from "react";
import imgGirl from "../../assets/images/placeholder/click-girl.png";
import imgGirl2 from "../../assets/images/galary/img4.png";
import Button from "../components/Button";
import { AiFillCheckCircle } from "react-icons/ai";

const PortfolioDetailSection = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light flex flex-col gap-6 items-start">
				<div className="flex flex-col lg:flex-row w-full items-center">
					<div className="w-full lg:w-[70%]">
						<img src={imgGirl} alt="img" className="w-full" />
					</div>
					<div className="bg-troo-secondary w-[80%] lg:w-[30%] p-8 -mt-6 lg:mt-0 lg:-ml-16 flex flex-col items-start justify-center gap-5 border border-troo-tertiary">
						<div>
							<h5>Clients</h5>
							<p className="text-troo-tertiary">Williams Camaroon</p>
						</div>
						<div>
							<h5>Date</h5>
							<p className="text-troo-tertiary">22 December, 2022</p>
						</div>
						<div>
							<h5>Category</h5>
							<p className="text-troo-tertiary">Family Photo</p>
						</div>
					</div>
				</div>
				<div className="flex items-start gap-4">
					<span className="font-extrabold text-3xl md:text-6xl py-2 px-6 bg-troo-secondary">
						L
					</span>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable. If you are going to use a passage of Lorem Ipsum, you
						need to be sure there isn't anything embarrassing hidden in the
						middle of text. All the Lorem Ipsum generators on the Internet tend
						to repeat predefined
					</p>
				</div>
				<p>
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered alteration in some form, by injected
					humour, or randomised words which don’t look even slightly believable.
					If you are going to use a passage of Lorem Ipsum, you need to be sure
					there isn’t anything embarrassing hidden in the middle of text. All
					the Lorem Ipsum generators on the Internet tend to repeat
					predefinedThere are many variations of passages of Lorem Ipsum
					available, but the majority have suffered alteration in some form, by
					injected humour, or randomised words which don’t look even slightly
					believable. If you are going to use and it is a passage of Lorem
					Ipsum, you need to be sure there isn’t anything embarrassing hidden.
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered alteration in some form, by injected
					humour, or randomised words which don’t look even slightly believable.
					If you are going to use a passage of Lorem Ipsum, you need to be sure
					there isn’t anything embarrassing hidden in the middle of text. All
					the Lorem Ipsum generators on the Internet tend to repeat predefined
				</p>
				<h6>Client’s Need </h6>
				<p>
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered alteration in some form, by injected
					humour, or randomised words which don't look even slightly believable
					alteration in some form, by injected humour, or randomised words which
					don't look even slightly believable.
				</p>
				<div className="w-full grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 items-center gap-3">
					<div className="flex flex-col items-start gap-4">
						<div className="flex items-center gap-3">
							<div>
								<AiFillCheckCircle className="text-3xl" />
							</div>
							<p>Every lorem ipsum dollr sit amet just dummy.</p>
						</div>
						<div className="flex items-center gap-3">
							<div>
								<AiFillCheckCircle className="text-3xl" />
							</div>
							<p>Using powerful lorem ipsum dollr sit amet just dummy.</p>
						</div>
						<div className="flex items-center gap-3">
							<div>
								<AiFillCheckCircle className="text-3xl" />
							</div>
							<p>Lorem ipsum dollr sit amet just dummy.</p>
						</div>
					</div>
					<div className="flex flex-col items-start gap-4">
						<div className="flex items-center gap-3">
							<div>
								<AiFillCheckCircle className="text-3xl" />
							</div>
							<p>Every lorem ipsum dollr sit amet just dummy.</p>
						</div>
						<div className="flex items-center gap-3">
							<div>
								<AiFillCheckCircle className="text-3xl" />
							</div>
							<p>Using powerful lorem ipsum dollr sit amet just dummy.</p>
						</div>
						<div className="flex items-center gap-3">
							<div>
								<AiFillCheckCircle className="text-3xl" />
							</div>
							<p>Lorem ipsum dollr sit amet just dummy.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PortfolioDetailSection;
