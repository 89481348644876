import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import ContactSection from "../shared/sections/ContactSection";
import PricingSection from "../shared/sections/PricingSection";

const Pricing = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Pricing" />}>
			<PricingSection noHead/>
			<ContactSection />
		</Layout>
	);
};

export default Pricing;
