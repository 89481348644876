import React from "react";
import Input from "./Input";
import Button from "./Button";

const Comments = () => {
	return (
		<form className="flex flex-col text-troo-light w-full gap-4">
			<h4>Comments (0)</h4>
			<p>Submit a Comment</p>
			<div className="w-full flex flex-col items-start gap-4">
				<div className="flex w-full flex-col items-start gap-1">
					<label>Name</label>
					<Input type="text" placeholder="Name" />
				</div>
				<div className="flex w-full flex-col items-start gap-1">
					<label>Email</label>
					<Input type="email" placeholder="Email" />
				</div>
				<div className="flex w-full flex-col items-start gap-1">
					<label>Message</label>
					<div className="flex md:col-span-2 items-start w-full px-1 py-3 justify-between border-b border-troo-light border-opacity-50">
						<textarea
							rows={5}
							placeholder="Message"
							className="bg-transparent outline-none w-full"
						/>
					</div>
				</div>
				<div className="form-control">
					<label className="cursor-pointer label">
						<input
							type="checkbox"
							// checked="checked"
							className="checkbox checkbox-info"
						/>
						<span className="ml-3 text-sm">
							Save My Name, Email, and Website in This Browser for the Next Time
							I Comment.
						</span>
					</label>
				</div>
				<Button className="btn-theme-outlined" type="submit">
					Submit Form
				</Button>
			</div>
		</form>
	);
};

export default Comments;
