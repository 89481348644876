import React from "react";
import Button from "../components/Button";
import { FaFacebookF } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const HeroSection = () => {
	return (
		<div className="py-8 w-full h-[800px]">
			<div className="relative main-container-big flex h-full gap-8 items-center justify-start">
				<div className="absolute bottom-[5%] left-24 hidden	2xl:flex flex-col items-center gap-4 text-troo-light">
					<div className="p-2 bg-troo-primary border border-troo-tertiary border-opacity-25 rounded-full">
						<FaFacebookF />
					</div>
					<div className="p-2 bg-troo-primary border border-troo-tertiary border-opacity-25 rounded-full">
						<AiOutlineTwitter />
					</div>
					<div className="p-2 bg-troo-primary border border-troo-tertiary border-opacity-25 rounded-full">
						<FaLinkedinIn />
					</div>
					<div className="p-2 bg-troo-primary border border-troo-tertiary border-opacity-25 rounded-full">
						<BsInstagram />
					</div>
					<div className="w-[2px] bg-troo-light h-60 bg-opacity-10"></div>
				</div>
				<div className="flex flex-col items-start text-troo-light gap-12">
					<div className="divider-line" />
					<h1>I am Monica Mackengy</h1>
					<h2>
						I am in love with photography. <br />
						Constantly shooting & finding <br />
						new inspirations.
					</h2>
					<div className="flex items-center gap-3">
						<Link to={ROUTES.contactPage}>
							<Button className="btn-theme-outlined">Contact Me</Button>
						</Link>
						<Link to={ROUTES.portfolio}>
							<Button className="btn-theme-filled">View My Clicks</Button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroSection;
