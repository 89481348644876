import React from "react";
import Input from "../components/Input";
import {
	BsCalendar,
	BsChat,
	BsClock,
	BsPerson,
	BsTelephone,
} from "react-icons/bs";
import { IoMailOpenOutline } from "react-icons/io5";
import Button from "../components/Button";

const BookAnAppointment = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				<div className="border border-troo-light border-opacity-25 p-4 sm:p-8 md:p-16">
					<form className="w-full flex flex-col items-start bg-troo-secondary gap-14 p-8 sm:p-10">
						<div className="flex flex-col items-start gap-3">
							<h5>Book An Appointment</h5>
							<div className="w-16 bg-troo-tertiary h-[1px]" />
						</div>
						<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
							<Input placeholder="Full Name" icon={<BsPerson />} />
							<Input placeholder="Phone No." icon={<BsTelephone />} />
							<Input placeholder="Email Adress" icon={<IoMailOpenOutline />} />
							<select className="select w-full bg-transparent rounded-none border-t-0 border-x-0 px-1 font-normal text-troo-light text-opacity-50 text-base outline-none border-b border-troo-light border-opacity-50">
								<option disabled selected>
									Select Enquiry Type
								</option>
								<option>Enquiry 1</option>
								<option>Enquiry 2</option>
								<option>Enquiry 3</option>
								<option>Enquiry 4</option>
							</select>
							<Input placeholder="22/12/2022" icon={<BsCalendar />} />
							<Input placeholder="10:00 AM" icon={<BsClock />} />
							<div className="flex md:col-span-2 items-start w-full px-1 py-3 justify-between border-b border-troo-light border-opacity-50">
								<textarea
									rows={5}
									placeholder="Message"
									className="bg-transparent outline-none w-full"
								/>
								<div className="text-troo-light text-lg">
									<BsChat />
								</div>
							</div>
						</div>
						<Button className="btn-theme-outlined">Submit Form</Button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default BookAnAppointment;
