import React from "react";

const Button = ({ children, className, link, ...rest }) => {
	return (
		<button
			className={`${className} ${
				className.includes("btn-theme-outlined") && "group"
			}`}
			{...rest}
		>
			<span
				className={`${
					className.includes("btn-theme-outlined") &&
					"group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"
				}`}
			></span>
			<p
				className={`${
					className.includes("btn-theme-outlined") &&
					"group-hover:text-troo-primary"
				}`}
			>
				{children}
			</p>
		</button>
	);
};

export default Button;
