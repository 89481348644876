import React from "react";

const Heading = ({ title, headline, align }) => {
	return (
		<div
			className={`flex flex-col ${
				align === "center" ? "items-center" : "items-start"
			} gap-4`}
		>
			<p
				className={`flex items-center gap-4 ${
					align === "center" ? "text-center" : "text-left"
				}`}
			>
				<span>{headline}</span>
				<span className="bg-troo-tertiary w-28 h-[2px]" />
			</p>
			<h2 className={`${align === "center" ? "text-center" : "text-left"}`}>
				{title}
			</h2>
		</div>
	);
};

export default Heading;
