import React from "react";
import { BsCalendar, BsPerson } from "react-icons/bs";
import { HiArrowLongRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const BlogCard = ({ img, heading }) => {
	return (
		<div className="flex flex-col items-center justify-center gap-5 text-troo-light p-4 border border-troo-light hover:opacity-100 transition-all cursor-pointer opacity-60 group">
			<h6 className="text-center">{heading}</h6>
			<Link to={ROUTES.portfolioDetails}>
				<div className="w-full h-[274px] overflow-hidden">
					<img src={img} alt={heading} className="w-full h-full object-cover" />
				</div>
			</Link>
			<div className="flex w-full gap-6 items-center justify-between">
				<div className="flex items-center gap-2">
					<BsPerson />
					By Admin
				</div>
				<div className="flex items-center gap-2">
					<BsCalendar />
					Dec 23 2022
				</div>
			</div>
			<div className="flex items-center gap-3">
				<Link to={ROUTES.portfolioDetails}>
					<h6 className="leading-none">Read full Article</h6>
				</Link>
				<HiArrowLongRight className="text-3xl -rotate-45 group-hover:rotate-0 transition-all" />
			</div>
		</div>
	);
};

export default BlogCard;
