import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import TeamSection from "../shared/sections/TeamSection";
import ContactSection from "../shared/sections/ContactSection";

const Team = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Team" />}>
			<TeamSection noHead/>
			<ContactSection />
		</Layout>
	);
};

export default Team;
