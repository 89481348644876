import React from "react";
import Heading from "../components/Heading";
import SectionLink from "../components/SectionLink";
import TeamCard from "../components/TeamCard";
import img1 from "../../assets/images/team/image-1.png";
import img2 from "../../assets/images/team/image-2.png";
import img3 from "../../assets/images/team/image-3.png";
import img4 from "../../assets/images/team/image-4.png";
import { ROUTES } from "../constants/routes";

const TeamSection = ({ noHead }) => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				{!noHead && (
					<div className="w-full flex flex-col lg:flex-row lg:items-center justify-between gap-8">
						<Heading
							headline="Our talened team"
							title={
								<>
									We picked best specialists
									<br /> that are suited just for you.
								</>
							}
						/>
						<SectionLink
							title="View All Team"
							shape="rectangle"
							link={ROUTES.team}
							isDark
						/>
					</div>
				)}
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
					<TeamCard
						img={img1}
						title="Kristiana Joe"
						designation="CEO and Founer"
					/>
					<TeamCard
						img={img2}
						title="Gabrielle Smith"
						designation="Photographer"
					/>
					<TeamCard
						img={img3}
						title="Genti Xhaxho"
						designation="Marketing Manager"
					/>
					<TeamCard
						img={img4}
						title="Anis Mashku"
						designation="UI/UX Designer"
					/>
					{noHead && (
						<>
							<TeamCard
								img={img1}
								title="Kristiana Joe"
								designation="CEO and Founer"
							/>
							<TeamCard
								img={img2}
								title="Gabrielle Smith"
								designation="Photographer"
							/>
							<TeamCard
								img={img3}
								title="Genti Xhaxho"
								designation="Marketing Manager"
							/>
							<TeamCard
								img={img4}
								title="Anis Mashku"
								designation="UI/UX Designer"
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default TeamSection;
