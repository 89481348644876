import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import ContactSection from "../shared/sections/ContactSection";
import ServiceDetailSection from "../shared/sections/ServiceDetailSection";

const ServiceDetails = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Services Details" />}>
			<ServiceDetailSection />
			<ContactSection />
		</Layout>
	);
};

export default ServiceDetails;
