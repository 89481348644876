import React from "react";
import Heading from "../components/Heading";
import flower from "../../assets/images/placeholder/flower.png";
import Aerial from "../../assets/images/services/Icon-1.svg";
import Videography from "../../assets/images/services/Icon-2.svg";
import Wedding from "../../assets/images/services/Icon-3.svg";
import Dress from "../../assets/images/services/Icon-4.svg";
import Camera from "../../assets/images/services/Icon.svg";
import { BsArrowUpRight } from "react-icons/bs";
import { ROUTES } from "../constants/routes";
import { Link } from "react-router-dom";

const ServiceSection = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:items-center justify-between gap-8">
					<Heading
						headline="What I Offer In My Service"
						title={
							<>
								I'll ensure you always get the
								<br /> best Photography Service
							</>
						}
					/>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable.
					</p>
				</div>
				<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:items-center justify-between gap-8">
					<div className="grid grid-cols-2 gap-8">
						<div className="flex flex-col items-center gap-3 md:gap-6">
							<div className="w-20 md:w-auto">
								<img
									src={Camera}
									alt="Camera"
									className="object-contain w-full h-full"
								/>
							</div>
							<h6 className="whitespace-nowrap">Photography</h6>
						</div>
						<div className="flex flex-col items-center gap-3 md:gap-6">
							<div className="w-20 md:w-auto">
								<img
									src={Videography}
									alt="Videography"
									className="object-contain w-full h-full"
								/>
							</div>
							<h6 className="whitespace-nowrap">Videography</h6>
						</div>
						<div className="flex flex-col items-center gap-3 md:gap-6">
							<div className="w-20 md:w-auto">
								<img
									src={Dress}
									alt="Dress"
									className="object-contain w-full h-full"
								/>
							</div>
							<h6 className="whitespace-nowrap">Fashion Photo</h6>
						</div>
						<div className="flex flex-col items-center gap-3 md:gap-6">
							<div className="w-20 md:w-auto">
								<img
									src={Aerial}
									alt="Aerial"
									className="object-contain w-full h-full"
								/>
							</div>
							<h6 className="whitespace-nowrap">Aerial Photo</h6>
						</div>
						<div className="flex flex-col items-center gap-3 md:gap-6">
							<div className="w-20 md:w-auto">
								<img
									src={Wedding}
									alt="Wedding"
									className="object-contain w-full h-full"
								/>
							</div>
							<h6 className="whitespace-nowrap">Wedding Photo</h6>
						</div>
						<div className="flex flex-col items-center justify-end text-troo-light opacity-40 transition-opacity cursor-pointer hover:opacity-100 gap-3 md:gap-6">
							<Link to={ROUTES.services}>
								<div className="p-5 bg-transparent text-xl lg:text-3xl border border-troo-light rounded-full">
									<BsArrowUpRight />
								</div>
							</Link>
							<h6 className="whitespace-nowrap">4 More Services</h6>
						</div>
					</div>
					<div className="w-full h-full overflow-hidden">
						<img
							src={flower}
							alt="flower"
							className="w-full h-full object-cover"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceSection;
