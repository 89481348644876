import React from "react";
import { BsStar } from "react-icons/bs";

const ReviewCard = () => {
	return (
		<div className="relative opacity-60 hover:opacity-100 transition-all p-10 border border-troo-light flex flex-col items-start text-troo-light gap-4">
			<div className="absolute right-8 -top-8 bg-troo-secondary w-16 h-16 gap-2 flex items-center justify-center border border-troo-light rounded-full p-5">
				<div className="w-2 h-full bg-troo-light rotate-12"></div>
				<div className="w-2 h-full bg-troo-light rotate-12"></div>
			</div>
			<div className="flex items-center gap-2 text-lg">
				<BsStar />
				<BsStar />
				<BsStar />
				<BsStar />
				<BsStar />
			</div>
			<p>
				Contrary to popular belief, Lorem Ipsum is not simply random text. It
				has roots in a piece of classical Latin literature from 45 BC, making
				Contrary to popular belief.
			</p>
			<div className="w-full h-[1px] bg-troo-tertiary" />
			<div className="flex flex-col items-start gap-3">
				<h6>Rose Gaventon</h6>
				<p>UI/UX Designer</p>
			</div>
		</div>
	);
};

export default ReviewCard;
