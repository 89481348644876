import React from "react";

const BlogCategory = () => {
	return (
		<div className="w-full flex flex-col items-start gap-5">
			<div className="bg-troo-secondary w-full text-troo-light px-6 py-5">
				<h6>Blogs Category</h6>
			</div>
			<div className="w-full grid grid-cols-1 gap-3">
				<div className="flex items-center justify-between px-6 py-5 bg-transparent border border-troo-light border-opacity-25">
					<p>Photography</p>
					<p>10</p>
				</div>
				<div className="flex items-center justify-between px-6 py-5 bg-transparent border border-troo-light border-opacity-25">
					<p>Videography</p>
					<p>10</p>
				</div>
				<div className="flex items-center justify-between px-6 py-5 bg-transparent border border-troo-light border-opacity-25">
					<p>Fashion Photography</p>
					<p>10</p>
				</div>
				<div className="flex items-center justify-between px-6 py-5 bg-transparent border border-troo-light border-opacity-25">
					<p>Advertising Shoot</p>
					<p>10</p>
				</div>
				<div className="flex items-center justify-between px-6 py-5 bg-transparent border border-troo-light border-opacity-25">
					<p>Model Photoshoot</p>
					<p>10</p>
				</div>
			</div>
		</div>
	);
};

export default BlogCategory;
