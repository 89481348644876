import React from "react";
import { BsCheckLg } from "react-icons/bs";

const ProcessCard = ({ srNo, headline, desc }) => {
	return (
		<div className="w-full flex flex-col gap-2 items-center justify-center">
			<div className="relative  p-4 border border-troo-light rounded-full aspect-square flex items-center justify-center">
				<h4 className="">{srNo}</h4>
				<span className="absolute top-0 -left-1 bg-troo-secondary p-1 border border-troo-light rounded-full aspect-square">
					<BsCheckLg />
				</span>
			</div>
			<h5 className="text-center">{headline}</h5>
			<p className="text-center">{desc}</p>
		</div>
	);
};

export default ProcessCard;
