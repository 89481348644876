import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";

const SubPageHeroSection = ({ title }) => {
	return (
		<div className="py-8 w-full h-[360px]">
			<div className="relative main-container-big flex h-full items-center justify-start">
				<div className="flex flex-col items-start text-troo-light gap-6">
					<h1>{title}</h1>
					<Breadcrumbs />
				</div>
			</div>
		</div>
	);
};

export default SubPageHeroSection;
