import React from "react";
import imgGirl from "../../assets/images/placeholder/click-girl.png";
import imgGirl2 from "../../assets/images/galary/img4.png";
import Button from "../components/Button";

const ServiceDetailSection = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light flex flex-col gap-6 items-start">
				<div className="flex flex-col lg:flex-row w-full items-center">
					<div className="w-full lg:w-[70%]">
						<img src={imgGirl} alt="img" className="w-full" />
					</div>
					<div className="bg-troo-secondary p-8 text-center -mt-6 lg:mt-0 lg:-ml-16 flex flex-col items-center justify-center gap-5 border border-troo-tertiary">
						<p>40% Off on Your First Project</p>
						<span className="w-10 h-[2px] bg-troo-light"></span>
						<h4>Do You Have an Any in Your Mind?</h4>
						<Button className="btn-theme-outlined">Contact With Me</Button>
					</div>
				</div>
				<div className="flex items-start gap-4">
					<span className="font-extrabold text-3xl md:text-6xl py-2 px-6 bg-troo-secondary">
						L
					</span>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable. If you are going to use a passage of Lorem Ipsum, you
						need to be sure there isn't anything embarrassing hidden in the
						middle of text. All the Lorem Ipsum generators on the Internet tend
						to repeat predefined
					</p>
				</div>
				<p>
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered alteration in some form, by injected
					humour, or randomised words which don’t look even slightly believable.
					If you are going to use a passage of Lorem Ipsum, you need to be sure
					there isn’t anything embarrassing hidden in the middle of text. All
					the Lorem Ipsum generators on the Internet tend to repeat
					predefinedThere are many variations of passages of Lorem Ipsum
					available, but the majority have suffered alteration in some form, by
					injected humour, or randomised words which don’t look even slightly
					believable. If you are going to use and it is a passage of Lorem
					Ipsum, you need to be sure there isn’t anything embarrassing hidden.
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered alteration in some form, by injected
					humour, or randomised words which don’t look even slightly believable.
					If you are going to use a passage of Lorem Ipsum, you need to be sure
					there isn’t anything embarrassing hidden in the middle of text. All
					the Lorem Ipsum generators on the Internet tend to repeat predefined
				</p>
				<h6>Our featured </h6>
				<p>
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered alteration in some form, by injected
					humour, or randomised words which don't look even slightly believable
					alteration in some form, by injected humour, or randomised words which
					don't look even slightly believable.
				</p>
				<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-3">
					<div className="flex flex-col items-start gap-4">
						<div className="w-full p-4 flex bg-troo-secondary gap-4 items-center">
							<h5 className="bg-troo-primary py-6 px-4">01</h5>
							<div className="flex flex-col items-start gap-2">
								<h6>Fast Service</h6>
								<p>There are many variations of passages by injected humour</p>
							</div>
						</div>
						<div className="w-full p-4 flex bg-troo-secondary gap-4 items-center">
							<h5 className="bg-troo-primary py-6 px-4">02</h5>
							<div className="flex flex-col items-start gap-2">
								<h6>Expert Team</h6>
								<p>There are many variations of passages by injected humour</p>
							</div>
						</div>
						<div className="w-full p-4 flex bg-troo-secondary gap-4 items-center">
							<h5 className="bg-troo-primary py-6 px-4">03</h5>
							<div className="flex flex-col items-start gap-2">
								<h6>24 X 7 Support</h6>
								<p>There are many variations of passages by injected humour</p>
							</div>
						</div>
					</div>
					<div className="flex flex-col items-start gap-4">
						<div className="w-full p-4 flex bg-troo-secondary gap-4 items-center">
							<h5 className="bg-troo-primary py-6 px-4">04</h5>
							<div className="flex flex-col items-start gap-2">
								<h6>Award Winning</h6>
								<p>There are many variations of passages by injected humour</p>
							</div>
						</div>
						<div className="w-full p-4 flex bg-troo-secondary gap-4 items-center">
							<h5 className="bg-troo-primary py-6 px-4">05</h5>
							<div className="flex flex-col items-start gap-2">
								<h6>Secure Payments</h6>
								<p>There are many variations of passages by injected humour</p>
							</div>
						</div>
						<div className="w-full p-4 flex bg-troo-secondary gap-4 items-center">
							<h5 className="bg-troo-primary py-6 px-4">06</h5>
							<div className="flex flex-col items-start gap-2">
								<h6>Work On Time</h6>
								<p>There are many variations of passages by injected humour</p>
							</div>
						</div>
					</div>
					<div className="w-full block md:hidden lg:block aspect-square overflow-hidden">
						<img
							src={imgGirl2}
							alt="img"
							className="w-full h-full object-cover"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceDetailSection;
