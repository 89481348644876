import React from "react";
import Input from "../components/Input";
import { BsChat, BsPerson, BsTelephone } from "react-icons/bs";
import { IoMailOpenOutline } from "react-icons/io5";
import Button from "../components/Button";
import Heading from "../components/Heading";
import { HiOutlineLocationMarker } from "react-icons/hi";

const ContactMeForm = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				<div className="flex flex-col lg:flex-row gap-12 w-full items-start">
					<form className="w-full lg:w-[60%] flex flex-col items-start gap-14">
						<Heading
							headline="Contact Me for Photography"
							title={
								<>
									Just Say Hi,
									<br /> We Will Reach You
								</>
							}
						/>
						<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
							<Input placeholder="Full Name" icon={<BsPerson />} />
							<Input placeholder="Last Name" icon={<BsPerson />} />
							<Input placeholder="Email Adress" icon={<IoMailOpenOutline />} />
							<Input placeholder="Phone No." icon={<BsTelephone />} />
							<select className="select w-full bg-transparent rounded-none border-t-0 border-x-0 px-1 font-normal text-troo-light text-opacity-50 text-base outline-none border-b border-troo-light border-opacity-50">
								<option disabled selected>
									Select Enquiry Type
								</option>
								<option>Enquiry 1</option>
								<option>Enquiry 2</option>
								<option>Enquiry 3</option>
								<option>Enquiry 4</option>
							</select>
							<select className="select w-full bg-transparent rounded-none border-t-0 border-x-0 px-1 font-normal text-troo-light text-opacity-50 text-base outline-none border-b border-troo-light border-opacity-50">
								<option disabled selected>
									What is Best time to contact you?
								</option>
								<option>Enquiry 1</option>
								<option>Enquiry 2</option>
								<option>Enquiry 3</option>
								<option>Enquiry 4</option>
							</select>
							<div className="flex md:col-span-2 items-start w-full px-1 py-3 justify-between border-b border-troo-light border-opacity-50">
								<textarea
									rows={5}
									placeholder="Message"
									className="bg-transparent outline-none w-full"
								/>
								<div className="text-troo-light text-lg">
									<BsChat />
								</div>
							</div>
						</div>
						<Button className="btn-theme-outlined">Submit Form</Button>
					</form>
					<div className="w-full lg:w-[40%] flex flex-col gap-8 items-start p-8 2xl:p-12 border border-troo-light border-opacity-25">
						<div className="flex flex-col gap-4 items-start">
							<h3>Contact With Me</h3>
							<span className="w-12 h-[1px] bg-troo-tertiary"></span>
						</div>
						<div className="flex flex-col items-start">
							<div className="flex items-center gap-3 text-troo-tertiary">
								<div className="p-4 text-2xl border border-troo-tertiary">
									<BsTelephone />
								</div>
								<div className="text-start">
									<h6>Call Me Any time</h6>
									<p>+44 123 456 7890</p>
								</div>
							</div>
						</div>
						<div className="flex flex-col items-start">
							<div className="flex items-center gap-3 text-troo-tertiary">
								<div className="p-4 text-2xl border border-troo-tertiary">
									<IoMailOpenOutline />
								</div>
								<div className="text-start">
									<h6>Email Me on</h6>
									<p>contact@troothems.com</p>
								</div>
							</div>
						</div>
						<div className="flex flex-col items-start">
							<div className="flex items-center gap-3 text-troo-tertiary">
								<div className="p-4 text-2xl border border-troo-tertiary">
									<HiOutlineLocationMarker />
								</div>
								<div className="text-start">
									<h6>Address</h6>
									<p>45 School Street Danbury, CT, Canada, 45892</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.9963544183383!2d72.7847093758056!3d21.19230378049834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04ddfbf486b7d%3A0x4efa1199e384241!2sTRooInbound%20Pvt.%20Ltd.%20%7CTop%20Rated%20WordPress%20and%20Expert%20Shopify%20Development%20Agency%20-%20Surat!5e0!3m2!1sen!2sin!4v1689064835307!5m2!1sen!2sin"
					width="600"
					height="450"
					style={{ border: 0 }}
					className="w-full"
					allowFullScreen
					title="map"
					loading="lazy"
					referrerpolicy="no-referrer-when-downgrade"
				></iframe>
			</div>
		</div>
	);
};

export default ContactMeForm;
