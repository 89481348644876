import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import ContactMeForm from "../shared/sections/ContactMeForm";

const ContactUS = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Contact Me" />}>
			<ContactMeForm />
		</Layout>
	);
};

export default ContactUS;
