import React from "react";
import Heading from "../components/Heading";
import SectionLink from "../components/SectionLink";
import camera from "../../assets/images/placeholder/camera.png";
import { FiCheckSquare } from "react-icons/fi";
import { ROUTES } from "../constants/routes";

const AboutSection = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				<div className="w-full flex flex-col lg:flex-row lg:items-center justify-between gap-8">
					<Heading
						headline="My Introduction"
						title={
							<>
								I do animals, fashion, and
								<br /> commercial photography
							</>
						}
					/>
					<SectionLink
						title="Learn More"
						shape="triangle"
						link={ROUTES.aboutUs}
					/>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
					<div className="flex flex-col items-start gap-6">
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable. If you are going to use a passage Lorem
							Ipsum, you need to be sure there isn't anything.
						</p>
						<img src={camera} alt="about" />
					</div>
					<div className="flex flex-col items-start gap-6">
						<p>
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don't look even
							slightly believable.
						</p>
						<div className="flex flex-col items-start gap-6">
							<h5>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Phasellus ultrices magna.
							</h5>
							<div className="flex flex-col items-start gap-4">
								<div className="flex items-start gap-3">
									<div>
										<FiCheckSquare className="text-xl" />
									</div>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									</p>
								</div>
								<div className="flex items-start gap-3">
									<div>
										<FiCheckSquare className="text-xl" />
									</div>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing
										elit.Lorem ipsum dolor siconse ctetur adipist amet.
									</p>
								</div>
								<div className="flex items-start gap-3">
									<div>
										<FiCheckSquare className="text-xl" />
									</div>
									<p>Lorem ipsum dolor sit amet consectetur adipis.</p>
								</div>
								<div className="flex items-start gap-3">
									<div>
										<FiCheckSquare className="text-xl" />
									</div>
									<p>
										Lorem There are many variations of passages of Lorem dolor
										sit.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutSection;
