import React from "react";
import Layout from "../shared/components/Layout";
import HeroSection from "../shared/sections/HeroSection";
import AboutSection from "../shared/sections/AboutSection";
import ServiceSection from "../shared/sections/ServiceSection";
import Galary from "../shared/sections/Galary";
import ClientReviews from "../shared/sections/ClientReviews";
import ContactBanner from "../shared/sections/ContactBanner";
import TeamSection from "../shared/sections/TeamSection";
import VideoBanner from "../shared/sections/VideoBanner";
import WorkProcess from "../shared/sections/WorkProcess";
import WhyChoose from "../shared/sections/WhyChoose";
import ContactSection from "../shared/sections/ContactSection";
import BlogSection from "../shared/sections/BlogSection";
import PricingSection from "../shared/sections/PricingSection";
import FAQSection from "../shared/sections/FAQSection";

const Home = () => {
	return (
		<Layout banner={<HeroSection />}>
			<AboutSection />
			<ServiceSection />
			<Galary />
			<ClientReviews />
			<ContactBanner />
			<TeamSection />
			<FAQSection />
			<VideoBanner />
			<PricingSection />
			<WorkProcess />
			<WhyChoose />
			<ContactSection isLight />
			<BlogSection />
		</Layout>
	);
};

export default Home;
