import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import Galary from "../shared/sections/Galary";
import ClientReviews from "../shared/sections/ClientReviews";
import ContactBanner from "../shared/sections/ContactBanner";
import TeamSection from "../shared/sections/TeamSection";
import ServiceGrid from "../shared/sections/ServiceGrid";
import ContactSection from "../shared/sections/ContactSection";

const Services = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Services" />}>
			<ServiceGrid />
			<Galary />
			<ClientReviews />
			<ContactBanner />
			<TeamSection />
			<ContactSection />
		</Layout>
	);
};

export default Services;
