import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import ContactSection from "../shared/sections/ContactSection";
import TeamDetailSection from "../shared/sections/TeamDetailSection";

const TeamDetails = () => {
	return (
		<Layout banner={<SubPageHeroSection title="About Cristiana Joe" />}>
			<TeamDetailSection />
			<ContactSection />
		</Layout>
	);
};

export default TeamDetails;
