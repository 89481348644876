import React from "react";
import Heading from "../components/Heading";
import SectionLink from "../components/SectionLink";
import ReviewCard from "../components/ReviewCard";
import { ROUTES } from "../constants/routes";

const ClientReviews = ({ noHead }) => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				{!noHead && (
					<div className="w-full flex flex-col lg:flex-row lg:items-center justify-between gap-8">
						<Heading
							headline="Our happy clients"
							title={
								<>
									What they are talking
									<br /> about my services
								</>
							}
						/>
						<SectionLink
							link={ROUTES.testimonial}
							title="Read More Review"
							shape="circle"
							noArrowBg
						/>
					</div>
				)}
				<div className="grid gap-x-4 gap-y-14 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full">
					<ReviewCard />
					<ReviewCard />
					<ReviewCard />
					<ReviewCard />
					<ReviewCard />
					<ReviewCard />
					{noHead && (
						<>
							<ReviewCard />
							<ReviewCard />
							<ReviewCard />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ClientReviews;
