import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { BsArrowUpRight } from "react-icons/bs";

const AboutExperience = () => {
	return (
		<div className="py-[100px] w-full bg-troo-secondary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
					<div className="flex flex-col items-start gap-6">
						<h5>Experiences</h5>
						<div className="bg-troo-tertiary w-40 h-[1px]" />
						<p className="text-troo-tertiary">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua.
						</p>
						<Link
							to={ROUTES.aboutUs}
							className="flex text-troo-tertiary items-center gap-2"
						>
							<h6>Contact me</h6> <BsArrowUpRight />
						</Link>
					</div>
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
						<div className="flex flex-col items-start gap-2">
							<p className="text-troo-tertiary">2019-Present</p>
							<h6>Markos Branding Ads</h6>
							<p className="text-troo-tertiary">Animation Design Lead</p>
							<div className="bg-troo-tertiary w-40 h-[1px] mt-3" />
						</div>
						<div className="flex flex-col items-start gap-2">
							<p className="text-troo-tertiary">2019-Present</p>
							<h6>Markos Branding Ads</h6>
							<p className="text-troo-tertiary">Animation Design Lead</p>
							<div className="bg-troo-tertiary w-40 h-[1px] mt-3" />
						</div>
						<div className="flex flex-col items-start gap-2">
							<p className="text-troo-tertiary">2017-2019</p>
							<h6>Barca Flamingo Star</h6>
							<p className="text-troo-tertiary">Animation Design Lead</p>
							<div className="bg-troo-tertiary w-40 h-[1px] mt-3" />
						</div>
						<div className="flex flex-col items-start gap-2">
							<p className="text-troo-tertiary">2017-2019</p>
							<h6>Barca Flamingo Star</h6>
							<p className="text-troo-tertiary">Animation Design Lead</p>
							<div className="bg-troo-tertiary w-40 h-[1px] mt-3" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutExperience;
