import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import Galary from "../shared/sections/Galary";
import ContactSection from "../shared/sections/ContactSection";

const Portfolio = () => {
	return (
		<Layout banner={<SubPageHeroSection title="Portfolio" />}>
			<Galary />
			<ContactSection />
		</Layout>
	);
};

export default Portfolio;
