import React from "react";
import Layout from "../shared/components/Layout";
import SubPageHeroSection from "../shared/sections/SubPageHeroSection";
import AboutSection from "../shared/sections/AboutSection";
import Galary from "../shared/sections/Galary";
import ClientReviews from "../shared/sections/ClientReviews";
import ContactBanner from "../shared/sections/ContactBanner";
import TeamSection from "../shared/sections/TeamSection";
import Pricing from "../shared/sections/PricingSection";
import WorkProcess from "../shared/sections/WorkProcess";
import WhyChoose from "../shared/sections/WhyChoose";
import ContactSection from "../shared/sections/ContactSection";
import AboutExperience from "../shared/sections/AboutExperience";

const About = () => {
	return (
		<Layout banner={<SubPageHeroSection title="About Me" />}>
			<AboutSection />
			<AboutExperience />
			<Galary />
			<ClientReviews />
			<ContactBanner />
			<TeamSection />
			<Pricing />
			<WorkProcess />
			<WhyChoose />
			<ContactSection />
		</Layout>
	);
};

export default About;
