import React from "react";
import Heading from "../components/Heading";
import {
	BsChat,
	BsEnvelopeOpen,
	BsTelephone,
	BsTelephonePlus,
} from "react-icons/bs";
import Jungle from "../../assets/images/placeholder/jungle.png";
import Input from "../components/Input";
import { BsPerson } from "react-icons/bs";
import { IoMailOpenOutline } from "react-icons/io5";
import Button from "../components/Button";

const ContactSection = ({ isLight }) => {
	return (
		<div
			className={`py-[100px] w-full ${
				isLight ? "bg-troo-secondary" : "bg-troo-primary"
			}`}
		>
			<div className="relative main-container-big-r-0 text-troo-light lg:flex-row items-center flex-col h-full flex w-full gap-16">
				<p className="absolute -left-0 opacity-40 hidden 2xl:block text-5xl -rotate-90 font-bold font-outline-2 text-transparent">
					CONTACT WITH ME
				</p>
				<div className="w-full flex flex-col gap-8 items-start pr-6 lg:pr-0">
					<Heading
						headline="Contact Me for Photography"
						title={
							<>
								Who are those lovely
								<br /> people contacting us?
							</>
						}
					/>
					<div className="flex md:flex-row flex-col items-center gap-10">
						<div className="flex items-center gap-4">
							<BsTelephonePlus className="text-xl md:text-3xl" />
							<div className="flex flex-col items-start">
								<p>Call me on</p>
								<h6>+44 123 456 7890</h6>
							</div>
						</div>
						<div className="flex items-center gap-4">
							<BsEnvelopeOpen className="text-xl md:text-3xl" />
							<div className="flex flex-col items-start">
								<p>Email me on</p>
								<h6>example@email.com</h6>
							</div>
						</div>
					</div>
					<form className="flex flex-col items-start gap-6 w-full">
						<div className="grid grid-cols-2 gap-6 w-full">
							<Input type="text" placeholder="Your Name" icon={<BsPerson />} />
							<Input
								type="text"
								placeholder="Your Phone"
								icon={<BsTelephone />}
							/>
							<Input
								type="email"
								placeholder="Your Email"
								icon={<IoMailOpenOutline />}
							/>
							<select className="select w-full bg-transparent rounded-none border-t-0 border-x-0 px-1 font-normal text-troo-light text-opacity-50 text-base outline-none border-b border-troo-light">
								<option disabled selected>
									Select Enquiry Type
								</option>
								<option>Enquiry 1</option>
								<option>Enquiry 2</option>
								<option>Enquiry 3</option>
								<option>Enquiry 4</option>
							</select>
							<div className="flex col-span-2 items-start w-full px-1 py-3 justify-between border-b border-troo-light">
								<textarea
									rows={5}
									placeholder="Message"
									className="bg-transparent outline-none w-full"
								/>
								<div className="text-troo-light text-lg">
									<BsChat />
								</div>
							</div>
						</div>
						<Button className="btn-theme-outlined">Submit Form</Button>
					</form>
				</div>
				<div className="w-full h-full hidden lg:block overflow-hidden">
					<img
						src={Jungle}
						alt="jungle"
						className="w-full h-full object-cover"
					/>
				</div>
			</div>
		</div>
	);
};

export default ContactSection;
