import React from "react";
import Img1 from "../../assets/images/blogs/image-1.png";
import Img2 from "../../assets/images/blogs/image-2.png";
import Img3 from "../../assets/images/blogs/image-3.png";
import BlogListCard from "../components/BlogListCard";
import BlogCategory from "../components/BlogCategory";
import BlogTags from "../components/BlogTags";
import BlogListWidget from "../components/BlogListWidget";

const BlogListSection = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light grid h-full grid-cols-1 lg:grid-cols-3 w-full gap-16">
				<div className="flex flex-col gap-8 w-full lg:col-span-2">
					<BlogListCard
						img={Img1}
						heading="This Weird Filter and Vintage Lens Create Amazing Images Together"
					/>
					<BlogListCard
						img={Img2}
						heading="How to Make a Lens from a Disposable Camera (90s film look)"
					/>
					<BlogListCard
						img={Img3}
						heading="Creating The Darkest Photo Backdrop in The World (99,9%)"
					/>
					<>
						<BlogListCard
							img={Img1}
							heading="This Weird Filter and Vintage Lens Create Amazing Images Together"
						/>
						<BlogListCard
							img={Img2}
							heading="How to Make a Lens from a Disposable Camera (90s film look)"
						/>
						<BlogListCard
							img={Img3}
							heading="Creating The Darkest Photo Backdrop in The World (99,9%)"
						/>
						<BlogListCard
							img={Img1}
							heading="This Weird Filter and Vintage Lens Create Amazing Images Together"
						/>
						<BlogListCard
							img={Img2}
							heading="How to Make a Lens from a Disposable Camera (90s film look)"
						/>
						<BlogListCard
							img={Img3}
							heading="Creating The Darkest Photo Backdrop in The World (99,9%)"
						/>
					</>
				</div>
				<div className="w-full flex flex-col gap-6">
					<BlogCategory />
					<BlogTags />
				</div>
			</div>
		</div>
	);
};

export default BlogListSection;
