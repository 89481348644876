import React from "react";
import { BsCalendar, BsPerson } from "react-icons/bs";
import { HiArrowLongRight } from "react-icons/hi2";

const BlogListCardSmall = ({ img, heading }) => {
	return (
		<div className="grid grid-cols-1 md:grid-cols-2 w-full items-center gap-2 text-troo-light p-2 border border-troo-light hover:opacity-100 transition-all cursor-pointer opacity-60 group">
			<div className="object-cover overflow-hidden">
				<img
					src={img}
					alt={heading}
					className="w-full h-full object-cover group-hover:scale-110 transition-all"
				/>
			</div>
			<h6 className="text-lg line-clamp-4">{heading}</h6>
		</div>
	);
};

export default BlogListCardSmall;
