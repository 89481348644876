import React from "react";
import Heading from "../components/Heading";
import SectionLink from "../components/SectionLink";
import PricingCard from "../components/PricingCard";
import { ROUTES } from "../constants/routes";

const PricingSection = ({ noHead }) => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				{!noHead && (
					<div className="w-full flex flex-col lg:flex-row lg:items-center justify-between gap-8">
						<Heading
							headline="Our pricing plan"
							title={
								<>
									Check what’s <br />
									our pricing we offer!
								</>
							}
						/>
						<SectionLink
							link={ROUTES.pricing}
							title="View All Plans"
							shape="cuttedCircle"
							noArrowBg
						/>
					</div>
				)}
				<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">
					<PricingCard title="Professional" price="39,99" badge="Hot" />
					<PricingCard title="Standard" price="69,99" badge="Popular" />
					<PricingCard title="Ultimate" price="99,99" badge="20% Off" />
					{noHead && (
						<>
							<PricingCard title="Professional" price="39,99" badge="Hot" />
							<PricingCard title="Standard" price="69,99" badge="Popular" />
							<PricingCard title="Ultimate" price="99,99" badge="20% Off" />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default PricingSection;
