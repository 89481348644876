import React from "react";
import { BsCalendar, BsPerson } from "react-icons/bs";
import { HiArrowLongRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const BlogListCard = ({ img, heading }) => {
	return (
		<Link to={ROUTES.portfolioDetails}>
			<div className="grid grid-cols-1 md:grid-cols-2 w-full justify-center gap-8 text-troo-light p-6 md:p-8 lg:p-10 border border-troo-light hover:opacity-100 transition-all cursor-pointer opacity-60 group">
				<div className="object-cover overflow-hidden">
					<img
						src={img}
						alt={heading}
						className="w-full h-full object-cover group-hover:scale-110 transition-all"
					/>
				</div>
				<div className="flex flex-col h-full justify-center gap-8">
					<div className="flex w-full gap-6 items-center justify-between">
						<div className="flex items-center gap-2">
							<BsPerson />
							By Admin
						</div>
						<div className="flex items-center gap-2">
							<BsCalendar />
							Dec 23 2022
						</div>
					</div>
					<h6 className="">{heading}</h6>
					<div className="flex items-center gap-3">
						<h6 className="leading-none">Read full Article</h6>
						<HiArrowLongRight className="text-3xl -rotate-45 group-hover:rotate-0 transition-all" />
					</div>
				</div>
			</div>
		</Link>
	);
};

export default BlogListCard;
