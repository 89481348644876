import React from "react";
import Heading from "../components/Heading";
import BlogCard from "../components/BlogCard";
import Img1 from "../../assets/images/blogs/image-1.png";
import Img2 from "../../assets/images/blogs/image-2.png";
import Img3 from "../../assets/images/blogs/image-3.png";
import Button from "../components/Button";
import { ROUTES } from "../constants/routes";
import { Link } from "react-router-dom";

const BlogSection = ({ noHead }) => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				{!noHead && (
					<Heading
						align="center"
						headline="Read My Blogs"
						title={
							<>
								Read our latest
								<br /> News and blog post
							</>
						}
					/>
				)}
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 w-full">
					<BlogCard
						img={Img1}
						heading="This Weird Filter and Vintage Lens Create Amazing Images Together"
					/>
					<BlogCard
						img={Img2}
						heading="How to Make a Lens from a Disposable Camera (90s film look)"
					/>
					<BlogCard
						img={Img3}
						heading="Creating The Darkest Photo Backdrop in The World (99,9%)"
					/>
					{noHead && (
						<>
							<BlogCard
								img={Img1}
								heading="This Weird Filter and Vintage Lens Create Amazing Images Together"
							/>
							<BlogCard
								img={Img2}
								heading="How to Make a Lens from a Disposable Camera (90s film look)"
							/>
							<BlogCard
								img={Img3}
								heading="Creating The Darkest Photo Backdrop in The World (99,9%)"
							/>
							<BlogCard
								img={Img1}
								heading="This Weird Filter and Vintage Lens Create Amazing Images Together"
							/>
							<BlogCard
								img={Img2}
								heading="How to Make a Lens from a Disposable Camera (90s film look)"
							/>
							<BlogCard
								img={Img3}
								heading="Creating The Darkest Photo Backdrop in The World (99,9%)"
							/>
						</>
					)}
				</div>
				{!noHead && (
					<Link to={ROUTES.blogsGrid} className="self-center">
						<Button className="btn-theme-outlined self-center">
							Read All Our Blogs
						</Button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default BlogSection;
