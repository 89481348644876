import React from "react";
import { BsCheck2 } from "react-icons/bs";
import Button from "./Button";

const PricingCard = ({ title, price, badge }) => {
	return (
		<div className="relative w-full opacity-70 hover:opacity-100 transition-all cursor-pointer flex flex-col border border-troo-tertiary gap-4 text-troo-light px-8 py-6 md:px-12 md:py-11">
			<div className="absolute top-6 right-6 px-4 py-2 bg-troo-tertiary bg-opacity-50 text-troo-light text-sm">
				{badge}
			</div>
			<h5>{title}</h5>
			<h4>$ {price}</h4>
			<p>/ per month</p>
			<p>
				Lorem ipsum dolor What’s Our Pricing sit amet, consectetur adipiscing.
			</p>
			<div className="w-full h-[1px] bg-troo-tertiary my-3" />
			<div className="w-full text-troo-light flex flex-col items-start gap-6">
				<div className="flex w-full items-center justify-between">
					<p>All features in startup</p>
					<div className="text-xl">
						<BsCheck2 />
					</div>
				</div>
				<div className="flex w-full items-center justify-between">
					<p>10 Photos and videos</p>
					<div className="text-xl">
						<BsCheck2 />
					</div>
				</div>
				<div className="flex w-full items-center justify-between">
					<p>Build tools and examples</p>
					<div className="text-xl">
						<BsCheck2 />
					</div>
				</div>
				<div className="flex w-full items-center justify-between">
					<p>Unlimited cloud storage</p>
					<div className="text-xl">
						<BsCheck2 />
					</div>
				</div>
				<div className="flex w-full items-center justify-between">
					<p>24 X 7 Hours support</p>
					<div className="text-xl">
						<BsCheck2 />
					</div>
				</div>
			</div>
			<Button className="btn-theme-filled self-start mt-4">
				Choose This Plan
			</Button>
		</div>
	);
};

export default PricingCard;
