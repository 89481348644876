import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "./Button";
import logo from "./../../assets/images/logo/brand.svg";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";
import { NAV_ROUTES } from "../constants/navRoutes";
import { ROUTES } from "../constants/routes";
import useWindowScroll from "../hooks/useWindowScroll";
import SecondarySidebar from "./SecondarySidebar";

const HeaderMenu = () => {
	const location = useLocation();
	const scrolled = useWindowScroll(90);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);
	return (
		<div
			className={`z-20 drawer drawer-end hidden md:flex w-full flex-col items-start ${
				scrolled
					? "bg-troo-primary fixed top-0"
					: "bg-transparent border-y border-troo-tertiary"
			}`}
		>
			<input id="my-drawer-1" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content main-container w-full flex items-center text-troo-tertiary justify-between">
				<div></div>
				<div className="flex w-full lg:w-auto items-center gap-4 lg:gap-6 2xl:gap-16 h-full ml-4 lg:ml-0">
					{NAV_ROUTES.map((route, i) => {
						if (!route?.isMultiple) {
							return (
								<Link
									key={i}
									to={route.path}
									className={`${
										location.pathname === route.path && "activeNav"
									} relative h-full flex items-center justify-center hover:text-troo-light py-4 text-sm lg:text-base`}
								>
									{route.name}
								</Link>
							);
						} else {
							return (
								<div
									key={i}
									className="dropdown dropdown-hover h-full flex items-center py-4 text-sm lg:text-base"
								>
									<label
										tabIndex={0}
										className="flex items-center gap-2 h-full"
									>
										<span>{route.name}</span>
										<AiOutlinePlus className="text-troo-tertiary text-xs" />
									</label>
									<ul
										tabIndex={0}
										className="dropdown-content p-0 bg-troo-light top-[100%] z-[1] menu shadow"
									>
										{route.subPages.map((subPage, i) => {
											return (
												<li
													key={i}
													className="bg-transparent text-troo-tertiary hover:bg-transparent hover:text-troo-secondary "
												>
													<Link
														to={subPage.path}
														className="hover:text-troo-primary px-6 py-3 text-sm xl:text-base"
													>
														{subPage.name}
													</Link>
												</li>
											);
										})}
									</ul>
								</div>
							);
						}
					})}
				</div>
				<label htmlFor="my-drawer-1" className="drawer-overlay">
					<HiOutlineMenuAlt3 className="text-3xl text-troo-light" />
				</label>
			</div>
			<div className="drawer-side z-50">
				<label htmlFor="my-drawer-1" className="drawer-overlay"></label>
				<div className="menu p-8 box-border 2xl:w-[25%] h-full overflow-scroll no-scrollbar bg-troo-primary text-troo-light">
					{/* Sidebar content here */}
					<div className="flex flex-col items-center gap-10 lg:gap-6 2xl:gap-8">
						<div className="flex items-center">
							<Link href={ROUTES.home}>
								<img src={logo} alt="trooPhotography" />
							</Link>
						</div>
						<div className="w-full h-[1px] bg-troo-light bg-opacity-25" />
						<h6>Our Services</h6>
						<SecondarySidebar />
						<div className="w-full text-center">
							<Button
								link={ROUTES.requestConsultation}
								className="btn-theme-outlined"
							>
								Go to Services
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeaderMenu;
