import React from "react";

const BlogTags = () => {
	return (
		<div className="w-full flex flex-col items-start">
			<div className="bg-troo-secondary w-full text-troo-light px-6 py-5">
				<h6>Tags</h6>
			</div>
			<div className="flex flex-wrap items-start w-full px-6 py-5 gap-3 border border-troo-light border-opacity-25">
				<p className="px-2 py-1 bg-troo-secondary">Photography</p>
				<p className="px-2 py-1 bg-troo-secondary">Commercial Shoot</p>
				<p className="px-2 py-1 bg-troo-secondary">Videography</p>
				<p className="px-2 py-1 bg-troo-secondary">Advertising</p>
				<p className="px-2 py-1 bg-troo-secondary">Fashion Shoot</p>
				<p className="px-2 py-1 bg-troo-secondary">Photoshoot</p>
			</div>
		</div>
	);
};

export default BlogTags;
