import React from "react";
import { BsFillPlayFill } from "react-icons/bs";

const VideoBanner = () => {
	return (
		<div className="w-full bg-video-banner bg-no-repeat bg-cover bg-center bg-fixed">
			<div className="main-container-big py-[110px] text-center text-troo-light h-full flex flex-col items-center justify-center w-full gap-8 bg-troo-primary bg-opacity-80">
				<div className="flex flex-col items-center gap-6">
					<h5>Intro video</h5>
					<div
						className="relative text-troo-secondary bg-troo-light p-6 text-5xl rounded-full mt-8 cursor-pointer"
						onClick={() => window.my_modal_2.showModal()}
					>
						<div className="absolute top-0 left-0 w-full h-full border border-troo-light scale-125 animate-ping rounded-full"></div>
						<BsFillPlayFill />
					</div>
					<h4>
						Brand experiences that are unforgettably amazing & <br />
						irresistible.
					</h4>
				</div>
			</div>
			<dialog id="my_modal_2" className="modal">
				<form
					method="dialog"
					className="modal-box w-11/12 max-w-5xl p-0 overflow-hidden"
				>
					<iframe
						width="100%"
						height="500"
						src="https://www.youtube.com/embed/DUfXdcpEfMs"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				</form>
				<form method="dialog" className="modal-backdrop">
					<button>close</button>
				</form>
			</dialog>
		</div>
	);
};

export default VideoBanner;
