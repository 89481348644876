import React from "react";
import Logo from "../../assets/images/logo/brand.svg";
import { FiPhoneCall } from "react-icons/fi";
import { FaFacebookF, FaLocationDot } from "react-icons/fa6";
import { IoMailOpenOutline } from "react-icons/io5";
import { BsArrowUp, BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";
const Footer = () => {
	return (
		<div className="w-full bg-troo-secondary">
			<div className="main-container-big grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-14">
				<div className="flex flex-col md:col-span-2 lg:col-span-1 items-start gap-4 text-troo-light">
					<img src={Logo} alt="logo" />
					<p>
						There are many variations of passages a of Lorem Ipsum available,
						but the majority and have suffered alteration in some form, by
						injected humour.
					</p>
					<div className="flex flex-wrap items-center gap-2">
						<h6>Connect</h6>
						<div className="flex items-center gap-2">
							<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
								<FaFacebookF />
							</div>
							<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
								<AiOutlineTwitter />
							</div>
							<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
								<FaLinkedinIn />
							</div>
							<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
								<BsInstagram />
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col lg:items-center gap-4 text-troo-light">
					<div className="flex flex-col gap-4">
						<h6>Useful links</h6>
						<div className="grid w-full grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-10 xl:gap-x-24 text-troo-tertiary">
							<Link to={ROUTES.home}>Home</Link>
							<Link to={ROUTES.aboutUs}>About Me</Link>
							<Link to={ROUTES.portfolio}>My Portfolio</Link>
							<Link to={ROUTES.blogsList}>My Blogs</Link>
							<Link to={ROUTES.services}>My Services</Link>
							<Link to={ROUTES.contactPage	}>Contact Me</Link>
						</div>
					</div>
				</div>
				<div className="flex flex-col lg:items-end gap-4 text-troo-light">
					<div className="flex flex-col items-start gap-4">
						<h6>Contact with Me </h6>
						<div className="flex flex-col items-start gap-3 text-troo-tertiary">
							<div className="flex items-center gap-3">
								<FiPhoneCall className="text-xl" />
								<p>+44 123 456 7890</p>
							</div>
							<div className="flex items-center gap-3">
								<IoMailOpenOutline className="text-xl" />
								<p>contact@troothemes.com</p>
							</div>
							<div className="flex items-center gap-3">
								<FaLocationDot className="text-xl" />
								<p>4516 School Street Danbury, CT, Canada</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="main-container-big flex flex-col gap-4 md:flex-row text-sm items-center justify-between text-troo-light py-4 border-t border-troo-tertiary">
				<p className="text-center md:text-left">
					Copyright © 2022. <span className="font-semibold">troothemes</span>.
					All rights reserved.
				</p>
				<div
					className="bg-troo-secondary -mt-14 border-opacity-50 text-opacity-50 hover:border-opacity-100 hover:text-opacity-100 transition-all -top-5 p-3 text-lg rounded-full border border-troo-light cursor-pointer"
					onClick={() => window.scrollTo(0, 0)}
				>
					<BsArrowUp />
				</div>
				<div className="flex items-center gap-4">
					<span>Terms & Conditions</span>
					<span>Privacy Policy</span>
				</div>
			</div>
		</div>
	);
};

export default Footer;
