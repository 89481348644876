import React from "react";
import Heading from "../components/Heading";
import GalaryCard from "../components/GalaryCard";
import Img1 from "../../assets/images/galary/img1.png";
import Img3 from "../../assets/images/galary/img2.png";
import Img2 from "../../assets/images/galary/img3.png";
import Img4 from "../../assets/images/galary/img4.png";
import Img5 from "../../assets/images/galary/img5.png";
import Img6 from "../../assets/images/galary/img6.png";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const Galary = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				<Heading
					headline="My latest design work"
					align="center"
					title={
						<>
							Visit my portfolio and
							<br /> give your feedback
						</>
					}
				/>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
					<GalaryCard img={Img1} category="Studio" title="Waterstory" />
					<GalaryCard img={Img2} category="Studio" title="Waterstory" />
					<GalaryCard img={Img3} category="Studio" title="Waterstory" />
					<GalaryCard img={Img4} category="Studio" title="Waterstory" />
					<GalaryCard img={Img5} category="Studio" title="Waterstory" />
					<GalaryCard img={Img6} category="Studio" title="Waterstory" />
				</div>
				<Button className="btn-theme-outlined self-center">
					<Link to={ROUTES.portfolio}>View My Clicks</Link>
				</Button>
			</div>
		</div>
	);
};

export default Galary;
