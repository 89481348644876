import React from "react";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const TeamCard = ({ img, designation, title }) => {
	return (
		<div className="relative w-full h-full overflow-hidden group cursor-pointer">
			<Link to={ROUTES.teamDetails}>
				<div className="flex transition-all -translate-y-[100%] group-hover:translate-y-0 z-10 absolute top-0 left-0 flex-col items-center justify-center w-full h-full bg-troo-secondary bg-opacity-70 backdrop-blur">
					<div className="absolute bottom-6 px-4 flex gap-2 flex-col items-center text-troo-light">
						<h5>{title}</h5>
						<p>{designation}</p>
						<div className="flex items-center gap-2">
							<div className="p-2 border border-troo-tertiary border-opacity-25 rounded-full">
								<FaFacebookF />
							</div>
							<div className="p-2 border border-troo-tertiary border-opacity-25 rounded-full">
								<AiOutlineTwitter />
							</div>
							<div className="p-2 border border-troo-tertiary border-opacity-25 rounded-full">
								<FaLinkedinIn />
							</div>
						</div>
					</div>
				</div>
			</Link>
			<Link to={ROUTES.teamDetails}>
				<img
					src={img}
					alt="img"
					className="w-full h-full object-cover group-hover:scale-125 transition-all"
				/>
			</Link>
		</div>
	);
};

export default TeamCard;
