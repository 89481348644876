import React from "react";
import { FaFacebookF } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import Logo from "../assets/images/logo/brand.svg";
import Input from "../shared/components/Input";
import { FaPaperPlane } from "react-icons/fa6";
const ComingSoon = () => {
	return (
		<div className="w-full h-screen bg-coming-soon bg-no-repeat bg-cover bg-center">
			<div className="w-full h-full bg-troo-primary text-troo-light bg-opacity-80 flex flex-col items-start justify-center p-8 md:p-20 lg:p-28 gap-5">
				<div className="w-full md:w-[80%] xl:w-[50%] flex flex-col gap-6 items-start">
					<img src={Logo} alt="logo" />
					<h6>New Website on The Way</h6>
					<p className="font-bold text-6xl md:text-8xl xl:text-9xl text-troo-tertiary">
						Be Patient
					</p>
					<p className="">
						There are many variations of passages of Lorem Ipsum available, but
						the and majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even humour, or
						randomised slightly believable..
					</p>
					<div>
						<p>Notify Me When it Launch</p>
					</div>
					<Input
						icon={
							<div className="p-3 bg-troo-primary text-troo-light">
								<FaPaperPlane />
							</div>
						}
						placeholder="Email"
						type="text"
					/>
					<div className="flex flex-wrap items-center gap-2">
						<h6>Connect</h6>
						<div className="flex items-center gap-2">
							<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
								<FaFacebookF />
							</div>
							<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
								<AiOutlineTwitter />
							</div>
							<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
								<FaLinkedinIn />
							</div>
							<div className="p-2 bg-troo-primary border border-troo-tertiary rounded-full">
								<BsInstagram />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ComingSoon;
