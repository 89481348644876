import React from "react";
import { HiArrowUpRight } from "react-icons/hi2";
import triangle from "../../assets/images/shapes/triangle.svg";
import circle from "../../assets/images/shapes/circle.svg";
import rectangle from "../../assets/images/shapes/rectangle.svg";
import cuttedCircle from "../../assets/images/shapes/cutted-circle.svg";
import { Link } from "react-router-dom";

const SectionLink = ({ title, shape, noArrowBg, isDark, link }) => {
	return (
		<div className="relative flex text-troo-tertiary items-center gap-6">
			<img
				src={
					shape === "triangle"
						? triangle
						: shape === "rectangle"
						? rectangle
						: shape === "circle"
						? circle
						: cuttedCircle
				}
				alt="shape"
				className={`hidden md:block ${
					shape === "circle"
						? "absolute -bottom-[150%] -left-[20%] w-[100px]"
						: shape === "rectangle"
						? "absolute -top-[48%] -left-[20%]"
						: "absolute -top-[10%] -left-[20%]"
				}`}
			/>
			<Link className="z-10" to={link}>
				<h5>{title}</h5>
			</Link>
			{!noArrowBg ? (
				<button
					className={`flex items-center justify-center p-4 rounded-full text-xl ${
						isDark ? "bg-troo-primary" : "bg-troo-secondary"
					}`}
				>
					<HiArrowUpRight />
				</button>
			) : (
				<HiArrowUpRight />
			)}
		</div>
	);
};

export default SectionLink;
