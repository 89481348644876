import React from "react";
import Heading from "../components/Heading";
import CameraLens from "../../assets/images/placeholder/why-choose-us.png";

const WhyChoose = () => {
	return (
		<div className="py-[100px] w-full bg-troo-primary">
			<div className="main-container-big text-troo-light h-full flex flex-col w-full gap-16">
				<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:items-center justify-between gap-8">
					<Heading
						headline="Why Choose My Services"
						title={
							<>
								Many reason our clients <br />
								work with us again & again
							</>
						}
					/>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable.
					</p>
				</div>
				<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:items-center justify-between gap-8">
					<div className="w-full h-full overflow-hidden">
						<img
							src={CameraLens}
							alt="flower"
							className="w-full h-full object-cover"
						/>
					</div>
					<div className="flex flex-col items-start gap-4">
						<h5>Number Speak many things</h5>
						<div className="w-full md:w-auto grid grid-cols-2">
							<div className="flex flex-col gap-2 p-4 w-full md:p-8 border border-troo-light items-center justify-center">
								<h3>20+</h3>
								<p className="text-center">Year of Experience</p>
							</div>
							<div className="flex flex-col gap-2 p-4 w-full md:p-8 border border-troo-light items-center justify-center">
								<h3>150+</h3>
								<p className="text-center">Happy Clients</p>
							</div>
							<div className="flex flex-col gap-2 p-4 w-full md:p-8 border border-troo-light items-center justify-center">
								<h3>500+</h3>
								<p className="text-center">Photo Shoot</p>
							</div>
							<div className="flex flex-col gap-2 p-4 w-full md:p-8 border border-troo-light items-center justify-center">
								<h3>15+</h3>
								<p className="text-center">Service Provide</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhyChoose;
