import React from "react";
import { SECONDARY_SIDEBAR } from "../constants/navRoutes";
import { HiArrowUpRight } from "react-icons/hi2";

const SecondarySidebar = () => {
	return (
		<div className="flex w-full flex-col items-start gap-4">
			{SECONDARY_SIDEBAR.map((route, i) => {
				return (
					<div
						className="flex items-center justify-between gap-5 bg-troo-secondary w-full px-7 py-3"
						key={i}
					>
						<div className="flex items-center gap-2">
							<div className="w-11 h-11 flex items-start justify-center overflow-hidden">
								<img
									src={route.img}
									alt={route.title}
									className="w-full h-full object-contain"
								/>
							</div>
							<h6 className="text-sm xl:text-base">{route.title}</h6>
						</div>
						<button className="p-4 bg-troo-primary rounded-full">
							<HiArrowUpRight />
						</button>
					</div>
				);
			})}
		</div>
	);
};

export default SecondarySidebar;
